<template>
	<div class="shadow-lg rounded">
		<b-overlay id="overlay-background" :show="isBusy" variant="secondary" opacity="0.8" blur="2px" rounded="sm">
			<b-row>
				<b-col sm="4" class="py-1 my-1 ml-1">
					<b-form-group
					  label="Filter"
					  label-for="filter-input"
					  label-cols-sm="1"
					  label-align-sm="right"
					  label-size="sm"
					  class="mb-0"
					>
					  <b-input-group size="sm">
					    <b-form-input
					      id="filter-input"
					      v-model="context"
					      type="search"
					      placeholder='Search on "name" or "uuid"'
					      debounce="1000"
					    ></b-form-input>

					    <b-input-group-append>
					      <b-button :disabled="!context" @click="context = ''">Clear</b-button>
					    </b-input-group-append>
					  </b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-table 
				ref="table" 
				responsive 
				small 
				striped 
				show-empty 
				:fields="fields" 
				:items="provider" 
				:busy.sync="isBusy" 
				:current-page="currentPage" 
				:per-page="perPage" 
				:sort-by.sync="sortedBy" 
				:sort-desc.sync="sortedDesc" 
				:no-border-collapse="false" 
				head-variant="light"
			>
				<template v-slot:cell(actions)="row">
					<b-button-group >
						<b-button :disabled="row.item.timestamp_set.length < 1" :variant="row.item.timestamp_set.length < 1 ? 'secondary' : 'primary'" @click="row.toggleDetails" v-b-tooltip.hover title="Expand Device"> <i class="fa fa-ellipsis-h"></i></b-button>
						<b-button variant="primary" @click="onClickEditDevice(row)" title="Edit Device"><i class="fa fa-pencil"></i></b-button>
					</b-button-group>
				</template>
				<template v-slot:cell(timestamp)="data">
					{{ data.item.timestamp_set.length > 0 ? formattedShortDateTime(data.item.timestamp_set[0].insert) : "No Data" }}
				</template>
				<template v-slot:cell(alarm)="data">
					{{ data.item.alarm ? data.item.alarm : "No Alarm" }}
				</template>
				<template v-slot:row-details="row">
					<template v-if="row.item.timestamp_set.length > 0">
						<b-card border-variant="primary" class="m-0 pt-2">
							<MedsTable :fields="detailed_fields" :items="row.item.timestamp_set" :customSlots="slots" class="px-0 mx-0">
								<!-- latest device data -->
								<template v-slot:slot-location="data">
									Long:{{data.data.item.location.longitude}} Lat:{{data.data.item.location.latitude}}
								</template>
								<template v-slot:slot-measurement="data">
									T1: {{data.data.item.measurement.temperature1}} | T2: {{data.data.item.measurement.temperature2}} | T3: {{data.data.item.measurement.temperature3}} | Lock: {{data.data.item.measurement.lock}} | Orientation: {{data.data.item.measurement.orientation}}
								</template>
							</MedsTable>
						</b-card>
					</template>
					<template v-else>
						<div class="p-3 bg-light">No new Data for {{ row.item.name }}</div>
					</template>
				</template>
				<template v-slot:custom-foot="scope">
					<b-tr class="border">
						<b-td colspan="1" class="align-middle pl-2" variant="secondary"> Records {{ startIndex }} tot {{ endIndex }} van {{ totalRows }} </b-td>
						<b-td colspan="6" class="align-middle" variant="secondary">
							<b-pagination v-if="showPagination" class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="middle" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
						</b-td>
					</b-tr>
				</template>
			</b-table>
		</b-overlay>
	</div>
</template>

<script>
import MedsTable from "@/components/MedsTable"
import { mapGetters, mapState, mapActions } from "vuex"
import ViewExtension from "@/views/ViewExtension"

export default ViewExtension.extend({
	name: "DeviceData",
	components: {
		MedsTable,
	},
	data() {
		return {
			fields: [
				{ key: "name", label: "Device" },
				{ key: "uuid", label: "ID" },
				{ key: "fw", label: "Firmware" },
				{ key: "type", label: "Type" },
				{ key: "timestamp", label: "Latest acquired data" },
				{ key: "alarm", label: "Alarm/ Last Alarm" },
				{ key: "actions", label: "Actions" },
			],
			detailed_fields: [
				{ key: "insert", label: "Time", formatter: (value) => this.formattedShortDateTime(value) },
				{ key: "location", label: "Location Data" },
				{ key: "measurement", label: "Measurement Data" },
				{ key: "boot_seq", label: "Boot seq" },
				{ key: "message_seq", label: "Msg seq" },
			],
			slots: [{ key: "measurement" }, { key: "location" }, ],

			currentPage: 1,
			perPage: 10,
			sortedBy: "id",
			sortedDesc: false,
			sortDirection: 'asc',
			totalRows: 0,
			context: null,
			filter: null,
			filterOn: [],
		}
	},
	computed: {
		startIndex() {
			return (this.currentPage - 1) * this.perPage + 1
		},
		endIndex() {
			var end = this.totalRows
			if (this.perPage < this.totalRows) {
				end = this.perPage * this.currentPage
				if (end > this.totalRows) {
					end = this.totalRows
				}
			}
			return end
		},
		showPagination() {
			return this.perPage < this.totalRows
		},
	},
	watch: {
		context(val) {
			this.filterContext()
		},
	},
	methods: {
		...mapActions("meds2go/device", ["filterNewData"]),
		provider(ctx, callback) {
			// this.loading()
			this.filterNewData({
				context: this.context,
				page: ctx.currentPage,
				per_page: ctx.perPage,
				sort_by: ctx.sortBy,
				sort_desc: ctx.sortDesc,
			}).then((results) => {
				callback(results.items)
				this.totalRows = results.tot_rows
			})
			// this.stopLoading()
		},
		filterContext() {
			this.$refs.table.refresh()
		},
		combineLocation(data) {
			let long = data.longitude
			let lat = data.latitude
			return long.toString() + "/" + lat.toString()
		},
		onClickEditDevice(row) {
			if (row.item.hasOwnProperty("id")) {
				this.$router.push(`/dashboard/device/${row.item.id}/modify`)
			}
		},
		onFiltered(filteredItems) {
	        // Trigger pagination to update the number of buttons/pages due to filtering
	        this.totalRows = filteredItems.length
	        this.currentPage = 1
      	},
	},
})
</script>
<style type="text/css">
b-tr {
	.no-hover {
		background-color: #ffffff;
	}
}
</style>
